"use client"

import Image, { type StaticImageData } from "next/image"
import Link from "next/link"
import { type Session } from "next-auth"
import { useState, type MouseEvent } from "react"
import { ChevronDown, ChevronUp, LogOut, Settings } from "react-feather"
import LogoPusatKendali from "shared-assets/icons/logo-pusat-kendali.svg"
import { Button, Dropdown, Avatar } from "shared-ui"
import { capitalize } from "shared-utils"

import { signOut, signIn } from "@/authentication/authService"
import { NonPenyediaRoleEnum, PenyediaRoleEnum } from "@/federatedGql/graphql"

type THeaderProps = {
  session: Session | null
}

const Header = ({ session }: THeaderProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const toggleDropdown = () => setShowDropdown((prev) => !prev)

  const getSettingsUrl = (personaId?: string) => {
    return `${process.env.NEXT_PUBLIC_ACCOUNT_URL}/settings/account-profile?personaId=${personaId}`
  }

  const handleSignin = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    signIn("auth0")
  }

  const handleSignout = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    signOut()
  }

  const getPersonaRole = () => {
    return `${
      session?.user?.role === PenyediaRoleEnum.AdminCompany ||
      session?.user?.role === PenyediaRoleEnum.MemberCompany
        ? "Penyedia"
        : "Non Penyedia"
    } (${
      session?.user?.role === NonPenyediaRoleEnum.Apip
        ? "APIP"
        : capitalize(session?.user?.role?.replaceAll("_", " ") || "")
    })`
  }

  return (
    <div className="fixed z-[999] mt-8 h-13 w-full bg-primary25 font-sans shadow-1">
      <div className="mx-auto flex w-full max-w-[1920px] items-center px-10 py-3">
        <div className="mr-auto flex h-[15.1px] items-center">
          <Link href="/">
            <Image
              data-test="header-logo"
              src={LogoPusatKendali as StaticImageData}
              alt="Pusat Kendali logo"
              width={140}
              height={50}
            />
          </Link>
        </div>
        {!session?.user && (
          <Button
            variant="solid"
            onClick={handleSignin}
            data-test="dropdown-option"
            id="login-button"
          >
            Masuk
          </Button>
        )}
        {session?.user && (
          <Dropdown
            data-test="header-dropdown"
            trigger={
              <div
                onClick={toggleDropdown}
                className="ml-auto flex w-auto cursor-pointer items-center gap-2 pr-10 sm:pr-0"
                data-test="header-userInfoSection"
              >
                <div className="mr-2">
                  <Avatar
                    online={false}
                    size="md"
                    src={{ text: session?.user?.fullName || "" }}
                  />
                </div>
                <div className="mr-1 flex flex-col">
                  <div
                    data-test="header-user-fullname"
                    className="text-caption-sm-semibold truncate sm:text-base"
                  >
                    {session.user.username || ""}
                  </div>
                  <div
                    data-test="header-user-role"
                    className="text-xs text-tertiary300"
                  >
                    {getPersonaRole()}
                  </div>
                </div>
                <div data-test="header-dropdown-button">
                  {showDropdown ? (
                    <ChevronUp width={18} />
                  ) : (
                    <ChevronDown width={18} />
                  )}
                </div>
              </div>
            }
          >
            <div
              className="w-24 text-sm no-underline sm:w-19"
              data-test="dropdown-options"
            >
              {!!session.user.username && (
                <Link
                  href={getSettingsUrl(session.user.personaId)}
                  data-test="header-profile-link"
                  target="__blank"
                >
                  <div
                    className="pointer flex items-center gap-2 px-5 py-3 transition-[background-color] duration-200 ease-in-out hover:underline"
                    style={{ widows: "100%" }}
                  >
                    <Settings width={18} />
                    <div data-test="dropdown-option">Profile</div>
                  </div>
                </Link>
              )}
              <button
                data-test="header-logout-button"
                className="flex cursor-pointer items-center gap-2 px-5 py-3 transition-[background-color] duration-200 ease-in-out hover:underline"
                style={{ widows: "100%" }}
                onClick={handleSignout}
              >
                <LogOut width={18} />
                <p data-test="dropdown-option">Keluar</p>
              </button>
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  )
}

export default Header
