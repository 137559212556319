import { IS_DEVELOPMENT } from "shared-utils"

export const API_MOCK_URL =
  "https://d081a3fe-af4d-4671-90b0-d4d9cee39043.mock.pstmn.io"

export const TYK_API_URL =
  process.env.NEXT_PUBLIC_TYK_URL || "https://gtp-tyk.eproc.dev/udg-stg/"

export const GRAPHQL_URL = IS_DEVELOPMENT
  ? "https://admin.eproc.dev/graphql"
  : `${process.env.NEXT_PUBLIC_AUTH_BASE_URL_INTERNAL}graphql`

export const GRAPHQL_WS_URL = GRAPHQL_URL.replace("https", "wss")

export const METABASE_SECRET_KEY = process.env.METABASE_SECRET_KEY || ""
export const METABASE_SITE_URL =
  process.env.NEXT_PUBLIC_METABASE_SITE_URL || "https://analytics.inaproc.id"

export const AUTH = {
  DOMAIN:
    process.env.NEXT_PUBLIC_AUTH_DOMAIN || "https://auth-staging.eproc.dev",
  AUDIENCE: process.env.AUTH_AUDIENCE || "",
  CLIENT_ID: process.env.AUTH_CLIENT_ID || "",
  CLIENT_SECRET: process.env.AUTH_CLIENT_SECRET || "",
  NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET || "",
}
