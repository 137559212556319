import { type MenuType } from "./type"
import { getDashboardUrl } from "@/app/(sidebar-layout)/dashboard/helpers/getDashboardId"

export const getMenuItems = (isMetabaseAkunINAPROC?: boolean) => {
  return [
    {
      icon: "Home",
      route: "/",
      title: "Beranda",
    },
    {
      icon: "Package",
      route: "#",
      title: "Produk",
      isParent: true,
      permissions: ["cm:view", "product:view"],
      submenuItems: [
        {
          permissions: ["cm:view"],
          route: "/category",
          title: "Daftar Kategori",
        },
        {
          permissions: ["product:view"],
          route: "/products",
          title: "Daftar Produk",
        },
      ],
    },
    {
      icon: "Monitor",
      route: "#",
      title: "Transaksi",
      isParent: true,
      permissions: ["order:view"],
      submenuItems: [
        {
          route: "/transaction/negotiation?status=ongoing",
          title: "Negosiasi",
        },
        {
          route: "/transaction/review-ppk?status=ongoing",
          title: "Persetujuan PPK",
        },
        {
          route: "/transaction/orders?status=ongoing",
          title: "Pesanan",
        },
      ],
    },
    {
      icon: "Clipboard",
      route: "#",
      title: "e-Audit",
      isParent: true,
      permissions: ["eaudit:view", "eaudit:action"],
      submenuItems: [
        {
          permissions: ["eaudit:action"],
          route: "#",
          isParent: true,
          title: "e-Katalog v5",
          submenuItems: [
            {
              route: `/dashboard/${getDashboardUrl("pembandingTransaksi")}`,
              title: "Pengawasan Transaksi",
            },
            {
              route: `/dashboard/${getDashboardUrl("pembandingProduk")}`,
              title: "Pengawasan Produk",
            },
            {
              route: `/dashboard/${getDashboardUrl("ringkasanAnomali")}`,
              title: "Ringkasan Anomali Transaksi",
            },
            {
              route: `#`,
              title: "Anomali Transaksi",
              submenuItems: [
                {
                  route: `/dashboard/${getDashboardUrl("perubahanHarga")}`,
                  title: "Transaksi Saat Harga Naik",
                },
                {
                  route: `/dashboard/${getDashboardUrl(
                    "ppkKePenyediaYangSamaDanBerulang"
                  )}`,
                  title: "Transaksi Berulang Ke Penyedia Tertentu",
                },
                {
                  route: `/dashboard/${getDashboardUrl(
                    "kecepatanTransaksiPertama"
                  )}`,
                  title: "Transaksi Produk Yang Baru Tayang",
                },
                {
                  route: `/dashboard/${getDashboardUrl(
                    "kecepatanStatusSuatuTransaksi"
                  )}`,
                  title: "Kecepatan Persetujuan Paket",
                },
              ],
            },
          ],
        },
        {
          permissions: ["eaudit:action"],
          route: "/eaudit/laporan-kertas-kerja",
          title: "Laporan Kertas Kerja",
        },
      ],
    },
    {
      icon: "Airplay",
      route: "#",
      title: "Monev",
      isParent: true,
      permissions: ["monev:view"],
      submenuItems: [
        {
          route: `/dashboard/${getDashboardUrl("kesesuaianTKDN")}`,
          title: "Kesesuaian TKDN",
        },
        {
          route: `/dashboard/${getDashboardUrl("kewajaranHargaProduk")}`,
          title: "Kewajaran Harga Produk",
        },
        {
          route: `/dashboard/${getDashboardUrl("substitusiProdukImpor")}`,
          title: "Substitusi Produk Impor",
        },
        ...(isMetabaseAkunINAPROC
          ? [
              {
                route: `/dashboard/${getDashboardUrl("akunINAPROC")}`,
                title: "Akun INAPROC",
              },
            ]
          : []),
      ],
    },
  ] as MenuType[]
}

export const KODE_SATKER_LKPP = "970968"
