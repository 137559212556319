import { graphql } from "@/federatedGql/gql"

export const GET_PROFILE = graphql(`
  query getProfile {
    me {
      ... on UserInfo {
        __typename
        id
        email
        name
        username
        phone
        profile {
          nik
          nip
        }
        status
        profileStatus
      }
      ... on GenericError {
        __typename
        reqId
        message
      }
    }
  }
`)

export const GET_INSTITUTION_BY_ID = graphql(`
  query getInstitutionById($id: String!) {
    getInstitutionByID(id: $id) {
      ... on Institution {
        __typename
        company {
          bentukUsaha
          bidangIndustri
          id
          name
          status
        }
        institution {
          institutionType
        }
        klpd {
          jenisKlpd
          kodeKlpd
          namaKlpd
          namaKlpdEcatalog
          namaKlpdPadi
          namaKlpdSakti
          namaKlpdSipd
          namaKlpdSirup
        }
        satker {
          institutionId
          jenisSatker
          kodeSatker
          namaSatker
          namaSatkerEcatalog
          namaSatkerPadi
          namaSatkerSakti
          namaSatkerSipd
          namaSatkerSirup
          namaSatkerTrx
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_PERSONA_NON_PENYEDIA = graphql(`
  query getPersonaNonpenyediaByID($id: String!) {
    getPersonaNonpenyediaByID(id: $id) {
      ... on PersonaNonPenyedia {
        __typename
        satker {
          namaSatker
          jenisSatker
          npwp
          alamat
        }
        klpd {
          namaKlpd
        }
        persona {
          unit
          penyediaJabatan
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)
