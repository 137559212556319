/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GraphQLClient } from "graphql-request"
import {
  type RequestMiddleware,
  type Response,
} from "graphql-request/build/esm/types"
import {
  type TRequest,
  checkUnauthorized,
  graphqlClientMiddlewareV2,
} from "shared-utils"

import { signIn, signOut } from "@/authentication/authService"
import { GRAPHQL_URL } from "@/config/api"
import { AUTH } from "@/config/client"

const middleware: RequestMiddleware = (request) => {
  const resultMiddleware = graphqlClientMiddlewareV2({
    request: request as TRequest,
    signOut,
    app: "INTERNAL",
    baseUrl: AUTH.BASE_URL,
  })
  return resultMiddleware
}

export const responseMiddleware: (
  response: Error | Response<unknown>
) => void = (response) => {
  if (checkUnauthorized(response)) {
    window && signIn("refreshToken")
  }
  return response
}
export const federatedGqlClient = new GraphQLClient(GRAPHQL_URL, {
  requestMiddleware: middleware,
  responseMiddleware: responseMiddleware,
  credentials: "include",
})
